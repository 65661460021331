<template>
  <div class="ad-wrapper" v-if="adData.length">
    <div class="split-text">
      <h3 class="title">{{ mainContent.title }}</h3>
      <p class="description">{{ mainContent.description }}</p>
      <template v-if="supportContent">
        <p class="description">
          <btn :text="supportContent.ad_link_text" :customClass="getCustomClasses" :href="supportContent.ad_link_url">
          </btn>
        </p>
      </template>
    </div>
    <div class="split-img">
      <img :src="determineImgUrl" :alt="mainContent.image_alt_text">
    </div>
  </div>
</template>

<script>
import Btn from '@/components/basic/Btn.vue';

export default {
  components: {
    Btn,
  },
  props: {
    adData: {
      type: Array,
      default() {
        return [];
      },
    },
    customButtonClass: {
      type: String,
      default: 'ad-link main-link',
    },
  },
  computed: {
    mainContent() {
      return this.adData.filter(ele => ele.type === 'ad_main')[0].fields;
    },
    supportContent() {
      return this.adData.filter(ele => ele.type === 'ad_support')[0]?.fields;
    },
    determineImgUrl() {
      if (this.mainContent.ad_image_url) return this.mainContent.ad_image_url;

      return this.mainContent.ad_image;
    },
    getCustomClasses() {
      return this.customButtonClass;
    },
  },
};
</script>

<style scoped="" lang="scss">
@import '../../styles/global/marketing.scss';

.home {
  .ad-wrapper {
    .split-text {
      margin: 0;
      color: $general;
    }

    .ad-link {
      margin-top: 2.5rem;
    }

    .title {
      @include card-title-font();

      margin-bottom: 1rem;
      @include bp-medium {
        margin-bottom: 0.5rem;
      }
    }

    .description {
      @include card-description-font();
      font-weight: 400;
      @include bp-xlarge {
        line-height: 2.25rem;
      }
    }
  }
}

.ad-wrapper {
  padding: 2rem 2rem 0 2rem;
  background: #f5f5f6;
  border-radius: 1rem;
  text-align: left;
  min-height: 18rem;

  @include bp-medium {
    display: flex;
    justify-content: space-between;
  }

  .split-text {
    max-width: 29rem;
    flex: 0 1 auto;
    align-self: center;
    margin: 0 auto;

    @include bp-medium {
      width: 50%;
    }
  }

  .split-img {
    width: 100%;
    max-width: 26rem;
    @include bp-medium {
      width: 50%;
    }
    align-self: flex-end;
    margin: 0 auto;
  }

  img {
    width: 100%;
    display: block;
  }

  .title {
    font-size: 1.5rem;
    line-height: 2rem;
    @include bp-mediumlarge {
      font-size: 2.5rem;
      line-height: 3rem;
    }

    font-weight: bold;
    margin: 0 0 0.5rem 0;
  }

  .description {
    line-height: 1.3rem;
  }

  .ad-link {
    margin-top: 1.2rem;
  }
}

.main-link {
  @include enhanced-link;
  color: $black;
  display: inline-flex;
  align-self: flex-start;
  text-decoration: none;
  border-bottom: 1px solid $black;

  .btn__content {
    font-weight: bold;
  }
}
</style>
